/* eslint-disable import/prefer-default-export */
import posthog from 'posthog-js';

export const identifyUser = ({ email }: { email: string }) => {
  posthog.identify(email, { email });
};

export const resetUser = () => {
  posthog.reset();
};
