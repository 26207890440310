'use client';

import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { EmotionCache } from '@emotion/cache';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';
import { GoogleTagManager } from '@next/third-parties/google';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import initializePostHog from 'config/initializePostHog';
import mediaQuery from 'css-mediaquery';
import NextApp, { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useReportWebVitals } from 'next/web-vitals';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import parser from 'ua-parser-js';

import 'reflect-metadata';

import Layout from '@/components/Layout/Layout';
import SocketProvider from '@/components/Socket/Socket.provider';
import {
  LOGIN,
  MESSAGES,
  VISIO_WAITING_SCREEN,
} from '@/data/navbar/navButtons';
import { COMMON_OPTIONS, ssrBreakpoint } from '@/designSystem/theme';
import * as locales from '@/locales';
import SponsoringProvider from '@/shared/SponsoringPromoBanner/context/SponsoringProvider';
import ToastProvider from '@/shared/Toast/Toast.provider';
import { NextPageWithLayout } from '@/types/nextType';
import { createQueryClient } from '@/utils/createQueryClient';

import '@/styles/index.css';

interface Survey {
  email: string;
  name: string;
  createdAt: Date;
  minTimeForDisplay: number;
  properties: {
    userType: string;
    environement: string;
  };
}

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    openAxeptioCookies?: () => void;
    delightedNps3: {
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
      survey: (arg: Survey) => void;
    };
  }
}

const queryClient = createQueryClient();
initializePostHog();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
  deviceType: 'mobile' | 'desktop';
}

export default function MyApp({
  Component,
  emotionCache,
  pageProps,
  deviceType,
}: MyAppProps) {
  const router = useRouter();
  const localeCopy = router.locale || router.defaultLocale;
  const messages = localeCopy
    ? locales[router.locale]
    : locales[router.defaultLocale];
  useReportWebVitals((metric) => {
    if (process.env.NODE_ENV !== 'production') {
      console.debug(metric);
    }
  });
  const ssrMatchMedia = (query) => ({
    matches: mediaQuery.match(query, {
      width:
        deviceType === 'mobile' ? ssrBreakpoint.mobile : ssrBreakpoint.desktop,
    }),
  });
  const theme = createTheme({
    ...COMMON_OPTIONS,
    ...(router.pathname.includes(VISIO_WAITING_SCREEN) && {
      palette: {
        ...COMMON_OPTIONS.palette,
        background: {
          default: '#000',
        },
      },
    }),
    components: {
      ...COMMON_OPTIONS.components,
      MuiUseMediaQuery: {
        defaultProps: {
          ssrMatchMedia,
        },
      },
    },
  });
  const getLayout =
    Component.getLayout ||
    ((page) => (
      <Layout
        isFooterDisplayed={
          pageProps?.isFooterDisplayed === undefined
            ? !router.pathname.includes(MESSAGES) &&
              !router.pathname.includes(LOGIN)
            : Boolean(pageProps?.isFooterDisplayed)
        }
        isNavbarDisplayed={pageProps?.isNavbarDisplayed}
        isTvBannerDisplayed={pageProps?.isTvBannerDisplayed}
      >
        {page}
      </Layout>
    ));

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <AppCacheProvider emotionCache={emotionCache}>
      <Head>
        <title>Les Sherpas</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID} />
      <IntlProvider
        locale={localeCopy}
        messages={messages}
        onError={() => null}
      >
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            {/** @ts-ignore */}
            <PostHogProvider client={posthog}>
              <Hydrate state={pageProps.dehydratedState}>
                <SocketProvider>
                  <ToastProvider>
                    <SponsoringProvider>
                      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                      <CssBaseline />
                      {getLayout(<Component {...pageProps} />)}
                      <ReactQueryDevtools
                        position="bottom-right"
                        initialIsOpen={false}
                      />
                    </SponsoringProvider>
                  </ToastProvider>
                </SocketProvider>
              </Hydrate>
            </PostHogProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </IntlProvider>
    </AppCacheProvider>
  );
}

MyApp.getInitialProps = async (context) => {
  const { ctx } = context;
  const device = {
    ...(ctx.req && {
      deviceType:
        parser(ctx.req.headers['user-agent']).device.type ?? 'desktop',
    }),
  };

  return {
    ...(await NextApp.getInitialProps(context)),
    ...device,
  };
};
