/* eslint-disable import/prefer-default-export */
import { TeacherApi } from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';
import server from 'config';

export const GET_MILESTONE_STATUS_URL = `teacher/private/get-milestones-status`;

export const apiGetMilestoneStatus = async ({ cookie }: { cookie: string }) => {
  const { data } = await axios.get<
    TeacherApi['private']['getMilestonesStatus']['response']
  >(`${server}${GET_MILESTONE_STATUS_URL}`, {
    headers: { cookie },
    validateStatus: (status) => status === 200,
    withCredentials: true,
  });
  return data;
};
