import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ds.organisms.login.modal.title',
    defaultMessage: `{variant, select, 
      login {Connectez-vous}
      other {Inscrivez-vous}
    }`,
  },
  subTitle: {
    id: 'ds.signup.modal.teacher.infos.title',
    defaultMessage: `pour contacter {firstName} `,
  },
  errorConnection: {
    id: 'ds.organisms.login.modal.error.connection',
    defaultMessage:
      'Désolé, nous ne trouvons aucun compte associé à ces informations de connexion. Veuillez vérifier vos informations ou créer un compte.',
  },
  footerMessage: {
    id: 'ds.organisms.login.modal.footer.message',
    defaultMessage: `{variant, select, 
      login {Pas encore de compte ?}
      other {Déjà inscrit ?}
    }`,
  },
  footerButton: {
    id: 'ds.organisms.login.modal.footer.button',
    defaultMessage: `{variant, select,
      login {Inscrivez-vous}
      other {Connectez-vous}
    }`,
  },
});
