/* eslint-disable import/prefer-default-export */

import type { AvatarProps } from '@/designSystem/Avatar/Avatar';

export const getHeightAndWidth = ({
  avatarSize,
}: {
  avatarSize: AvatarProps['avatarSize'];
}): { width: number; height: number } => {
  switch (avatarSize) {
    case 'xsmall':
      return { width: 20, height: 20 };
    case 'small':
      return { width: 24, height: 24 };
    case 'medium':
      return { width: 32, height: 32 };
    case 'large':
      return { width: 36, height: 36 };
    case 'xlarge':
      return { width: 44, height: 44 };
    case '2xlarge':
    default:
      return { width: 68, height: 68 };
  }
};
