/* eslint-disable import/prefer-default-export */

export const defaultSearchSubjects = [
  [{ text: 'Maths', highlight: false }],
  [{ text: 'Français', highlight: false }],
  [{ text: 'Physique', highlight: false }],
  [{ text: 'Anglais', highlight: false }],
  [{ text: 'Economie', highlight: false }],
  [{ text: 'Droit', highlight: false }],
];
