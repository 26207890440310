import { useMemo, useState } from 'react';

type Validator = {
  validator: (_value: unknown) => boolean;
  message: string;
};

type Field<T> = {
  name: string;
  validators: Validator[];
  defaultValue?: T;
};

type Props<T> = {
  fields: Field<T[keyof T]>[];
};

export type UseFormInputChange<T> = ({
  // eslint-disable-next-line no-unused-vars
  name,
  // eslint-disable-next-line no-unused-vars
  value,
}: {
  name: string;
  value: T;
}) => void;

export type UseFormInputBlur<T> = UseFormInputChange<T>;

type UseFormReturn<T> = {
  values: T;
  isFormValid: boolean;
  errors: Errors;
  handleInputChange: UseFormInputChange<T[keyof T]>;
  handleInputBlur: UseFormInputBlur<T[keyof T]>;
  handleFormSubmit: () => void;
  validate: () => boolean;
};

export type Errors = Record<string, string[]>;

function useForm<T>({ fields }: Props<T>): UseFormReturn<T> {
  const [errors, setErrors] = useState<Errors>({});
  const [hasFormChanged, setHasFormChanged] = useState(false);
  const [blurredFields, setBlurredFields] = useState<string[]>(
    fields
      .filter((field) => 'defaultValue' in field && field.defaultValue != null)
      .map(({ name }) => name)
  );
  const [values, setValues] = useState<T>(
    fields
      .filter((field) => 'defaultValue' in field)
      .reduce((prev, next) => {
        // eslint-disable-next-line no-param-reassign
        prev[next.name] = next.defaultValue;
        return prev;
      }, {} as T)
  );
  const [isValidOnDemand, setIsValidOnDemand] = useState(false);

  const validateField = (field: Field<T[keyof T]>, value: T[keyof T]) => {
    const errorMessages = field.validators.flatMap(({ message, validator }) => {
      const isFieldValid = validator(value);
      if (!isFieldValid) {
        return [message];
      }
      return [];
    });
    setErrors((prev) => ({ ...prev, [field.name]: errorMessages }));
    return errorMessages;
  };

  const validateInput = (name: string, value: T[keyof T]) => {
    const field = fields.find((field) => field.name === name);
    validateField(field, value);
  };

  const validate = () => {
    const validations = fields?.flatMap((field) =>
      validateField(field, values[field.name])
    );
    const isValid = validations.length === 0;
    setIsValidOnDemand(isValid);
    return isValid;
  };

  const isFormValid = useMemo(
    () =>
      isValidOnDemand ||
      (hasFormChanged &&
        !fields.some((field) =>
          field.validators.some(
            ({ validator }) => !validator(values[field.name])
          )
        )),
    [fields, hasFormChanged, values, isValidOnDemand]
  );

  const handleInputBlur: UseFormInputBlur<T[keyof T]> = ({ name, value }) => {
    if (!blurredFields.includes(name)) {
      setBlurredFields((prev) => [...prev, name]);
      validateInput(name, value);
    }
  };

  const handleInputChange: UseFormInputChange<T[keyof T]> = ({
    name,
    value,
  }) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setHasFormChanged(true);
    if (blurredFields.includes(name)) validateInput(name, value);
  };

  const handleSubmit = async () => {
    setHasFormChanged(false);
  };

  return {
    values,
    isFormValid,
    errors,
    handleInputChange,
    handleInputBlur,
    handleFormSubmit: handleSubmit,
    validate,
  };
}

export default useForm;
