import server from 'config';

import { OnboardingStudentDrawerSettings } from '@/components/OnBoardingStudent/common/OnboardingStudent.type';
import { ONBOARDING_STUDENT_DRAWER_SETTINGS } from '@/data/navbar/navButtons';
import useLocalStorage from '@/hooks/useLocalStorage';
import { LoginSignUpVariant } from '@/Organisms/LoginSignUpModal/LoginSignUp.type';
import useNavigation from '@/shared/Hooks/useNavigation';
import { Provider } from '@/types/provider';

type Props = {
  provider: Provider;
  variant?: LoginSignUpVariant;
};

const useGafamConnection = ({ provider, variant = 'login' }: Props) => {
  const { pushTo, urls } = useNavigation();

  const { getItem } = useLocalStorage<OnboardingStudentDrawerSettings>(
    ONBOARDING_STUDENT_DRAWER_SETTINGS
  );

  const onClick = () => {
    const items = getItem();

    if (variant === 'signup') {
      pushTo(urls.drawerSignUp());
      return;
    }

    const redirectUrl = new URL(`${server}auth/${provider}`);

    if (variant === 'login') {
      redirectUrl.searchParams.set(
        'redirectError',
        `${window.location.pathname}`
      );
    }

    if (items.origin === 'announce') {
      redirectUrl.searchParams.set('redirect', items.drawerExitUrl);
    }

    if (items.origin === 'announceNavbar') {
      redirectUrl.searchParams.set(
        'redirect',
        `${window.location.origin}/${items.drawerExitUrl}`
      );
    }

    if (items.origin === 'search') {
      redirectUrl.searchParams.set('redirect', items.drawerExitUrl);
    }

    window.location.assign(redirectUrl);
  };

  return {
    onClick,
  };
};

export default useGafamConnection;
